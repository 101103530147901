<template>
  <div class="container-banners block-container">
    <div class="container safe-banner-container mobile-hide">
      <div class="safe-banner-col">
        <div class="banners-title">
          {{ $t('components.Home.Banners.Safe.title') }}
        </div>
        <div class="safe-banner-sticker">
          <img src="@/assets/img/stripe.png" />
        </div>
        <div class="safe-banner-row">
          <div class="btn-orange" @click="navigateTo('/create-assignment')">
            {{ $t('components.Home.Banners.Safe.use_button') }}
          </div>
          <div class="safe-banner-description">
            {{ $t('components.Home.Banners.Safe.protectedByStripe') }}
          </div>
        </div>
      </div>
      <div class="safe-banner-col">
        <div class="safe-banner-row safe-banner-row-cards">
          <div class="safe-banner-card">
            <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
            <div class="safe-banner-card-number">1</div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.step1') }}
            </div>
          </div>
          <div class="safe-banner-card">
            <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
            <div class="safe-banner-card-number">2</div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.step2') }}
            </div>
          </div>
        </div>
        <div
          class="safe-banner-row safe-banner-row-cards safe-banner-row-cards-middle"
        >
          <div class="safe-banner-card">
            <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
            <div class="safe-banner-card-number">3</div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.step3') }}
            </div>
          </div>
          <div class="safe-banner-card">
            <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
            <div class="safe-banner-card-number">4</div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.step4') }}
            </div>
          </div>
        </div>
        <div class="safe-banner-row safe-banner-row-cards">
          <div class="safe-banner-card">
            <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
            <div class="safe-banner-card-number">5</div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.step5') }}
            </div>
          </div>
          <div class="safe-banner-card">
            <div class="safe-banner-card-number">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.21 15.54L0 9.33L2.83 6.5L6.21 9.89L16.09 0L18.92 2.83L6.21 15.54Z"
                  fill="#FF6B17"
                />
              </svg>
            </div>
            <div class="safe-banner-card-text">
              {{ $t('components.Home.Banners.Safe.stepConfirm') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container desktop-hide">
      <div class="block-title">
        {{ $t('components.Home.Banners.Safe.stepConfirm') }}
      </div>

      <div class="safe-banner-row safe-banner-row-cards">
        <div class="safe-banner-card">
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <div class="safe-banner-card-number">1</div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.step1') }}
          </div>
        </div>
        <div class="safe-banner-card">
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <div class="safe-banner-card-number">2</div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.step2') }}
          </div>
        </div>
        <div class="safe-banner-card">
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <div class="safe-banner-card-number">3</div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.step3') }}
          </div>
        </div>
        <div class="safe-banner-card">
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <div class="safe-banner-card-number">4</div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.step1') }}
          </div>
        </div>
        <div class="safe-banner-card">
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <div class="safe-banner-card-number">5</div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.step5') }}
          </div>
        </div>
        <div class="safe-banner-card">
          <div class="safe-banner-card-number">
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.21 15.54L0 9.33L2.83 6.5L6.21 9.89L16.09 0L18.92 2.83L6.21 15.54Z"
                fill="#FF6B17"
              />
            </svg>
          </div>
          <div class="safe-banner-card-text">
            {{ $t('components.Home.Banners.Safe.stepConfirm') }}
          </div>
        </div>
      </div>
      <div class="safe-banner-sticker">
        <img src="@/assets/img/stripe.png" />
      </div>
      <div class="btn-orange" @click="navigateTo('/create-assignment')">
        {{ $t('components.Home.Banners.Safe.use_button') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables';

  .btn-orange {
    width: 241px;
    height: 69px;

    @media screen and (width <= 728px) {
      margin: 20px auto 30px;
    }
  }

  .safe-banner {
    &-col {
      position: relative;
    }

    &-row {
      display: flex;
      align-items: center;
      margin-top: 24px;

      @media screen and (width <= 728px) {
        flex-direction: column;
      }

      &-cards {
        gap: 16px;
        margin-top: 0;
        margin-left: 100px;

        @media screen and (width <= 728px) {
          margin-left: 0;
          gap: 4px;
        }

        &:not(:first-child) {
          margin-top: 12px;
        }

        &-middle {
          margin-left: 0;
        }
      }
    }

    &-description {
      width: 260px;
      margin-left: 17px;
      font-size: 14px;
    }

    &-sticker {
      position: relative;
      top: 0;
      left: 0;
      text-align: right;
      margin-top: 1rem;
      margin-right: 3rem;

      @media screen and (width <= 728px) {
        text-align: center;
        margin-right: 0;
      }
    }

    &-container {
      display: flex;
    }

    &-card {
      border-radius: $border-radius-medium;
      border: 1px solid #1e272f2b;
      padding: 12px 28px 12px 12px;
      display: flex;
      align-items: center;

      &-number {
        border: 1px solid #1e272f2b;
        border-radius: 100px;

        //width: 50px;
        min-width: 50px;
        height: 50px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-text {
        margin-left: 16px;
        font-size: 14px;
        width: 153px;
      }
    }
  }
</style>
