import type {Review} from './review.interface';

export const reviews: Review[] = [
  {
    id: 0,
    clientName: 'Jan',
    clientRating: 5,
    serviceProviderName: 'Aneta N',
    serviceProviderRating: 5,
    category: 'Úklid',
    content:
      'Objednala jsem úklid bytu přes GetJob a všechno proběhlo skvěle! Specialista přijel včas, uklidil vše dokonale, nemusela jsem nic opravovat. Jsem velmi spokojená, doporučuji!',
  },
  {
    id: 1,
    clientName: 'Michal',
    clientRating: 5,
    serviceProviderName: 'Jiří S',
    serviceProviderRating: 5,
    category: 'Stěhovací služby',
    content:
      'Stěhoval jsem se s pomocí týmu z GetJob a bylo to nejlepší rozhodnutí! Všechno bylo rychlé, pečlivé a bez stresu. Velké díky za profesionalitu!',
  },
  {
    id: 2,
    clientName: 'Tomáš',
    clientRating: 4,
    serviceProviderName: 'Eva L',
    category: 'Instalatér',
    serviceProviderRating: 5,
    content:
      'Potřebovala jsem rychlou opravu umyvadla a přes GetJob jsem našla instalatéra. Mistr přijel a vše rychle a pečlivě opravil. Teď už vím, kam se obrátit!',
  },
  {
    id: 3,
    clientName: 'Josef',
    clientRating: 4.5,
    serviceProviderRating: 5,
    serviceProviderName: 'Martin V',
    category: 'Doučování z matematiky',
    content:
      'Dlouho jsem hledal dobrého doučovatele pro syna, přes GetJob jsem našel skvělého specialistu. Hodiny jsou zajímavé a syn začal předmět opravdu lépe chápat.',
  },
  {
    id: 4,
    clientName: 'Adam',
    clientRating: 4.5,
    serviceProviderName: 'Alena R',
    serviceProviderRating: 5,
    category: 'Elektrikář',
    content:
      'Objednala jsem si přes GetJob pomoc s elektroinstalací. Technik byl velmi slušný, udělal vše pečlivě. Všechno teď funguje, jak má, doporučím přátelům!',
  },
  {
    id: 5,
    clientName: 'Karel',
    clientRating: 4.5,
    serviceProviderName: 'Václav Novotný',
    serviceProviderRating: 4,
    category: 'Stěhovací služby',
    content:
      'Stěhovali jsme se do nového bytu, našli stěhováky přes GetJob. Všechno proběhlo skvěle: rychle, bez poškození a za přijatelnou cenu.',
  },
  {
    id: 6,
    clientName: 'Václav',
    clientRating: 5,
    serviceProviderName: 'Olga Procházková',
    serviceProviderRating: 5,
    category: 'Úklid',
    content:
      'Velké díky za úžasný úklid! Přes GetJob jsem snadno našla odborníka, který přijel včas a vše dokonale uklidil. Určitě objednám znovu!',
  },
  {
    id: 7,
    clientName: 'Marek',
    clientRating: 5,
    serviceProviderName: 'Daniel Janda',
    serviceProviderRating: 5,
    category: 'Instalatér',
    content:
      'Rozbil se mi kohoutek, zavolal jsem instalatéra přes GetJob. Technik přijel, odstranil problém. Všechno skvělé, doporučuji!',
  },
  {
    id: 8,
    clientName: 'Jiří',
    clientRating: 5,
    serviceProviderName: 'Marie K',
    serviceProviderRating: 5,
    category: 'Doučování z angličtiny',
    content:
      'GetJob mi pomohl najít skvělého doučovatele pro dceru. Doučují se už měsíc a už vidíme pokrok. Jsme velmi spokojení!',
  },
  {
    id: 9,
    clientName: 'Petr',
    clientRating: 5,
    serviceProviderName: 'Tomás Dvořák',
    serviceProviderRating: 5,
    category: 'Elektrikář',
    content:
      'Potřeboval jsem nainstalovat lustr, zavolal jsem elektrikáře přes GetJob. Práce byla provedena rychle a kvalitně, určitě znovu využiji služby.',
  },
  {
    id: 10,
    clientName: 'Lukáš',
    clientRating: 5,
    serviceProviderName: 'Lenka Svobodová',
    serviceProviderRating: 5,
    category: 'Úklid',
    content:
      'Dlouho jsem nemohla najít dobrou úklidovou firmu, dokud jsem nevyzkoušela GetJob. Specialista všechno uklidil do lesku, byt září. Skvělá služba!',
  },
  {
    id: 11,
    clientName: 'Lucie',
    clientRating: 4.5,
    serviceProviderName: 'Petr P',
    serviceProviderRating: 5,
    category: 'Stěhovací služby',
    content:
      'Přes GetJob jsem našel skvělý tým na stěhování nábytku. Všechno bylo pečlivé a rychle zvládnuto. Doporučuji!',
  },
  {
    id: 12,
    clientName: 'Anna',
    clientRating: 4.5,
    serviceProviderName: 'Světlana M',
    serviceProviderRating: 4,
    category: 'Instalatér',
    content:
      'Měla jsem únik vody pod umyvadlem, zavolala jsem specialistu přes GetJob. Technik přijel a vše důkladně opravil. Jediné, co bylo, lehce posunul čas příjezdu, ale to nevadí.',
  },
  {
    id: 13,
    clientName: 'Tereza',
    clientRating: 4,
    serviceProviderName: 'Natalia Ivanova',
    serviceProviderRating: 5,
    category: 'Doučování z ruštiny',
    content:
      'Díky GetJob jsem našla skvělého doučovatele pro syna. Po několika lekcích začal lépe rozumět gramatice. Moc děkuji!',
  },
  {
    id: 14,
    clientName: 'Eva',
    clientRating: 4.5,
    serviceProviderName: 'Andrej Kovář',
    serviceProviderRating: 4,
    category: 'Elektrikář',
    content:
      'Potřeboval jsem pomoc s výměnou zásuvek, využil jsem službu přes GetJob. Technik všechno profesionálně opravil bez zbytečných otázek. Doporučuji!',
  },
  {
    id: 15,
    clientName: 'Markéta',
    clientRating: 4.5,
    serviceProviderName: 'Ahmed H',
    serviceProviderRating: 4,
    category: 'Elektrikář',
    content:
      'Potřeboval jsem odborníka na instalaci kabeláže v novém bytě. Specialista přes GetJob byl skvělý: vše udělal rychle a kvalitně. Jsem velmi spokojený!',
  },
  {
    id: 16,
    clientName: 'Helena',
    clientRating: 4.5,
    serviceProviderName: 'Juan Martinez',
    serviceProviderRating: 4,
    category: 'Elektrikář',
    content:
      'Objednal jsem si stěhovací služby přes GetJob. Přesun proběhl bez problémů, vše bylo v pořádku. Posunuli čas o hodinu, ale to nezkazilo dojem.',
  },
];
