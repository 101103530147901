<template>
  <router-link class="category-card category-all-btn" to="/categories">
    <div class="category-all-btn-text">
      {{
        $t('components.Home.Categories.CategoryAllBtn.showAllServices_button')
      }}
    </div>
    <div class="category-all-btn-circle">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 17.25L17.25 1M17.25 1V16.6M17.25 1H1.65"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </router-link>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
  .category-all-btn {
    &-text {
      font-size: 32px;
      font-weight: 500;
      padding: 1.5rem;
      max-width: 200px;
    }

    &-circle {
      width: 64px;
      height: 64px;
      background: #ff6b17;
      border-radius: 50%;
      position: relative;
      margin-top: 30px;
      margin-left: 10px;

      @media screen and (width <= 728px) {
        margin-left: auto;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      opacity: 0.75;
      cursor: pointer;
      border-color: #ff6b17;

      .category-card-title {
        color: #ff6b17;
      }
    }
  }

  .category-card {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 38px 202px 0 #1e272f1c;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
      border-color: #ff6b17;

      .category-all-btn-text {
        color: #ff6b17;
      }
    }
  }
</style>
