<template>
  <div class="stars-container" :class="{'stars-container_small': small}">
    <div v-for="i in 5" :key="i">
      <svg
        v-if="i <= rating"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6516 12.0584L19.7527 16.4956L21.2202 23.1019C21.2979 23.4473 21.2757 23.8087 21.1565 24.1411C21.0373 24.4734 20.8264 24.7619 20.55 24.9705C20.2737 25.1791 19.9442 25.2986 19.6029 25.3139C19.2615 25.3292 18.9233 25.2398 18.6307 25.0567L13.079 21.562L7.5392 25.0567C7.24654 25.2398 6.90838 25.3292 6.567 25.3139C6.22562 25.2986 5.89617 25.1791 5.61983 24.9705C5.3435 24.7619 5.13254 24.4734 5.01334 24.1411C4.89414 23.8087 4.87198 23.4473 4.94964 23.1019L6.41496 16.5024L1.51499 12.0584C1.25583 11.8264 1.06842 11.5202 0.976282 11.1781C0.884141 10.836 0.891365 10.4733 0.997046 10.1355C1.10273 9.79766 1.30216 9.49973 1.57034 9.27906C1.83852 9.05839 2.16351 8.92481 2.50454 8.89506L8.96325 8.31448L11.4844 2.07348C11.616 1.746 11.8381 1.46627 12.1226 1.26952C12.4072 1.07276 12.7414 0.967773 13.0833 0.967773C13.4252 0.967773 13.7595 1.07276 14.044 1.26952C14.3285 1.46627 14.5506 1.746 14.6822 2.07348L17.211 8.31448L23.6675 8.89506C24.0085 8.92481 24.3335 9.05839 24.6017 9.27906C24.8699 9.49973 25.0693 9.79766 25.175 10.1355C25.2807 10.4733 25.2879 10.836 25.1958 11.1781C25.1036 11.5202 24.9162 11.8264 24.6571 12.0584H24.6516Z"
          fill="#FF6B17"
        />
      </svg>

      <svg
        v-else-if="i === Number(rating.toFixed())"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_153_2379"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="26"
          height="26"
        >
          <path
            d="M24.4786 12.0586L19.5798 16.4959L21.0473 23.1022C21.1249 23.4475 21.1028 23.809 20.9835 24.1413C20.8643 24.4737 20.6534 24.7622 20.3771 24.9708C20.1007 25.1794 19.7713 25.2988 19.4299 25.3142C19.0885 25.3295 18.7504 25.24 18.4577 25.057L12.906 21.5622L7.36623 25.057C7.07357 25.24 6.73541 25.3295 6.39403 25.3142C6.05265 25.2988 5.7232 25.1794 5.44686 24.9708C5.17053 24.7622 4.95957 24.4737 4.84037 24.1413C4.72117 23.809 4.69901 23.4475 4.77666 23.1022L6.24198 16.5027L1.34202 12.0586C1.08285 11.8267 0.895449 11.5204 0.803308 11.1784C0.711167 10.8363 0.718391 10.4736 0.824072 10.1357C0.929754 9.7979 1.12919 9.49997 1.39737 9.2793C1.66555 9.05863 1.99053 8.92505 2.33157 8.89531L8.79027 8.31472L11.3114 2.07372C11.4431 1.74625 11.6651 1.46652 11.9497 1.26976C12.2342 1.07301 12.5684 0.968018 12.9103 0.968018C13.2522 0.968018 13.5865 1.07301 13.871 1.26976C14.1555 1.46652 14.3776 1.74625 14.5093 2.07372L17.038 8.31472L23.4945 8.89531C23.8356 8.92505 24.1605 9.05863 24.4287 9.2793C24.6969 9.49997 24.8963 9.7979 25.002 10.1357C25.1077 10.4736 25.1149 10.8363 25.0228 11.1784C24.9306 11.5204 24.7432 11.8267 24.4841 12.0586H24.4786Z"
            fill="#DADBDD"
          />
        </mask>
        <g mask="url(#mask0_153_2379)">
          <path
            d="M24.4786 12.0586L19.5798 16.4959L21.0473 23.1022C21.1249 23.4475 21.1028 23.809 20.9835 24.1413C20.8643 24.4737 20.6534 24.7622 20.3771 24.9708C20.1007 25.1794 19.7713 25.2988 19.4299 25.3142C19.0885 25.3295 18.7504 25.24 18.4577 25.057L12.906 21.5622L7.36623 25.057C7.07357 25.24 6.73541 25.3295 6.39403 25.3142C6.05265 25.2988 5.7232 25.1794 5.44686 24.9708C5.17053 24.7622 4.95957 24.4737 4.84037 24.1413C4.72117 23.809 4.69901 23.4475 4.77666 23.1022L6.24198 16.5027L1.34202 12.0586C1.08285 11.8267 0.895449 11.5204 0.803308 11.1784C0.711167 10.8363 0.718391 10.4736 0.824072 10.1357C0.929754 9.7979 1.12919 9.49997 1.39737 9.2793C1.66555 9.05863 1.99053 8.92505 2.33157 8.89531L8.79027 8.31472L11.3114 2.07372C11.4431 1.74625 11.6651 1.46652 11.9497 1.26976C12.2342 1.07301 12.5684 0.968018 12.9103 0.968018C13.2522 0.968018 13.5865 1.07301 13.871 1.26976C14.1555 1.46652 14.3776 1.74625 14.5093 2.07372L17.038 8.31472L23.4945 8.89531C23.8356 8.92505 24.1605 9.05863 24.4287 9.2793C24.6969 9.49997 24.8963 9.7979 25.002 10.1357C25.1077 10.4736 25.1149 10.8363 25.0228 11.1784C24.9306 11.5204 24.7432 11.8267 24.4841 12.0586H24.4786Z"
            fill="#DADBDD"
          />
          <path
            d="M-1.18848 -2.88696H14.2318C12.3003 8.94341 11.8527 16.0622 14.2318 29.8811H-1.18848V-2.88696Z"
            fill="#FF6B17"
          />
        </g>
      </svg>

      <svg
        v-else
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.3917 12.0586L19.4928 16.4959L20.9603 23.1022C21.038 23.4475 21.0158 23.809 20.8966 24.1413C20.7774 24.4737 20.5665 24.7622 20.2901 24.9708C20.0138 25.1794 19.6844 25.2988 19.343 25.3142C19.0016 25.3295 18.6634 25.24 18.3708 25.057L12.8191 21.5622L7.27931 25.057C6.98665 25.24 6.64849 25.3295 6.30711 25.3142C5.96573 25.2988 5.63628 25.1794 5.35995 24.9708C5.08361 24.7622 4.87266 24.4737 4.75346 24.1413C4.63425 23.809 4.6121 23.4475 4.68975 23.1022L6.15507 16.5027L1.2551 12.0586C0.995937 11.8267 0.808535 11.5204 0.716394 11.1784C0.624253 10.8363 0.631477 10.4736 0.737158 10.1357C0.84284 9.7979 1.04227 9.49997 1.31045 9.2793C1.57863 9.05863 1.90362 8.92505 2.24465 8.89531L8.70336 8.31472L11.2245 2.07372C11.3561 1.74625 11.5782 1.46652 11.8627 1.26976C12.1473 1.07301 12.4815 0.968018 12.8234 0.968018C13.1653 0.968018 13.4996 1.07301 13.7841 1.26976C14.0686 1.46652 14.2907 1.74625 14.4223 2.07372L16.9511 8.31472L23.4076 8.89531C23.7486 8.92505 24.0736 9.05863 24.3418 9.2793C24.61 9.49997 24.8094 9.7979 24.9151 10.1357C25.0208 10.4736 25.028 10.8363 24.9359 11.1784C24.8437 11.5204 24.6563 11.8267 24.3972 12.0586H24.3917Z"
          fill="#999DA1"
          fill-opacity="0.36"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
  const {rating} = withDefaults(
    defineProps<{
      rating: number;
      small?: boolean;
    }>(),
    {
      rating: 0,
    },
  );
</script>

<style lang="scss" scoped>
  .stars-container {
    display: flex;
    align-items: center;
    gap: 5px;

    &_small {
      > div,
      svg {
        width: 12px;
        height: 100%;
      }
    }
  }

  svg {
    fill: #999da1;

    &:not(:first-child) {
      margin-left: 5px;
    }

    cursor: pointer;
  }
</style>
